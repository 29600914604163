import type { FormValidationTranslationsObject } from "../../translation-keys/form-validation-keys.js";

const FormValidation: FormValidationTranslationsObject = {
  eclipse_Country_Error: "Veuillez saisir votre pays",
  eclipse_FirstName_Error: "Veuillez saisir votre prénom",
  eclipse_LastName_Error: "Veuillez saisir votre nom de famille",
  eclipse_Email_Error: "Veuillez entrer votre addresse mail professionnelle",
  eclipse_Company_Error: "Veuillez saisir votre entreprise",
  eclipse_Phone_Error: "Veuillez saisir votre numéro de téléphone",
  eclipse_Tier__c_Error: "Veuillez indiquer la taille de votre entreprise",
  eclipse_Industry_Error: "Veuillez saisir votre secteur d'activité",
  eclipse_Department__c_Error: "Veuillez saisir votre service",
  eclipse_Email_Opt_In__c_Error: "",  //need info on this
  eclipse_contactRequested_Error: "", //Not currently used
  eclipse_Webform_Comments_recent_value__c_Error: "", //need info on this
  eclipse_Latest_Solution_Interest__c_Error: "", //need info on this
  eclipse_Entity_Error: "",
  eclipse_NonBusinessEmail_Error:"Veuillez saisir votre adresse e-mail",
  eclipse_Partner_Opt_in_Processing__c_Error:"",
};

export default FormValidation;
